<template>
    <div class="data">
        <div class="opt_path mb20">
            <span @click="$router.push({ path: `/race` })">插花比赛</span>
            <span>数据分析</span>
        </div>
        <div class="ls_flexalign_center race_info">
            <div>
                <div>{{ info.competitionName }}</div>
                <div>面向标签：{{ info.groupNames && info.groupNames.join("") }}</div>
                <div>比赛时间：{{ info.competitionStartTime }} - {{ info.competitionEndTime }}</div>
            </div>
            <div class="ls_flex_1"></div>
            <div class="ls_flexalign_center">
                <div>
                    <div>{{ info.totalCount }}</div>
                    <div>参赛人数</div>
                </div>
                <div>
                    <div>{{ info.uncompletedCount }}</div>
                    <div>未完成人数</div>
                </div>
                <div>
                    <div>{{ info.aveTime }}分钟</div>
                    <div>平均完成时间</div>
                </div>
            </div>
        </div>
        <div class="ls_flexalign_center search_part">
            <el-select v-model="params.status" size="medium">
                <el-option v-for="item in statusOptions" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
            <el-select v-model="params.groupId" placeholder="全部标签" size="medium">
                <el-option label="全部" :value="''" />
                <el-option v-for="dict in courseList" :key="dict.id" :label="dict.name" :value="dict.id" />
            </el-select>
            <el-input v-model.trim="params.studentUserName" size="medium" placeholder="请输入姓名"></el-input>

            <el-button size="medium" plain @click="(params.pageIndex = 1), getList()">
                查询
            </el-button>
            <div class="ls_flex_1"></div>
            <el-button size="medium" type="success" @click="lookChart">
                排行榜
            </el-button>
        </div>

        <el-table :data="list" highlight-current-row :header-cell-style="{ background: '#FAFAFA' }" style="width: 100%" @sort-change="sort">

            <el-table-column label="用户">
                <div class="ls_flexalign_center" slot-scope="scope">
                    <img :src="scope.row.studentUserAvatar | imgFormat" style="height: 60px;width: 60px;margin-right: 10px;" />
                    <span>{{ scope.row.studentUserName }}</span>
                </div>
            </el-table-column>
            <el-table-column label="班级">
                <div class="ls_flexalign_center" slot-scope="scope">
                    <span>{{ scope.row.groupNames && scope.row.groupNames.join(",") }}</span>
                </div>
            </el-table-column>
            <el-table-column label="完成状态">
                <template slot-scope="scope">
                    <span>{{ statusOptions.find(item => item.value == scope.row.status).label }}</span>
                </template>
            </el-table-column>
            <el-table-column label="完成时间">
                <template slot-scope="scope">
                    {{ scope.row.answerTime ? scope.row.answerTime + "分钟" : "-" }}
                </template>
            </el-table-column>
            <el-table-column label="分数" sortable="custom">
                <template slot-scope="scope">
                    {{ scope.row.score || "-" }}
                </template>
            </el-table-column>
            <el-table-column label="排名">
                <template slot-scope="scope">
                    {{ scope.row.rank || "-" }}
                </template>
            </el-table-column>
        </el-table>
        <div class="page_part">
            <el-pagination v-if="total" :current-page.sync="params.pageIndex" :page-sizes="[10, 15, 20, 25]" :page-size.sync="params.pageSize" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="getList" @current-change="getList" />
        </div>
        <chartDialog ref="chartDialog" />
    </div>
</template>

<script>
import chartDialog from "../components/chartDialog.vue"
export default {
    components: {
        chartDialog
    },
    data () {
        return {
            params: {
                id: "",
                status: "",
                queryType: 1,
                studentUserName: "",
                groupId: "",
                pageIndex: 1,
                pageSize: 10,
            },
            total: 0,
            statusOptions: [
                { value: '', label: "全部" },
                { value: 1, label: "未提交" },
                { value: 2, label: "未评分" },
                { value: 3, label: "已评分" },
                { value: 4, label: "超时" },
            ],
            info: {},
            courseList: [],
            list: [],
        };
    },
    methods: {
        sort (e) {
            this.params.sort = e.order;
            this.params.pageIndex = 1;
            this.getList();
        },
        getList () {
            this.$ajax.post("/competition/queryAnalyseList", {
                ...this.params,
                id: this.$route.query.id,
            }).then(response => {
                this.list = response.list;
                this.total = response.total;
            });
        },
        getInfo () {
            this.$ajax.get("/competition/getAnalyseStatistics", {
                params: { id: this.$route.query.id }
            }).then(response => {
                this.info = response.data;
            });
        },
        lookChart () {
            this.$refs.chartDialog.showDialog();
        },
    },
    created () {
        this.getList();
        this.getInfo();
        this.$ajax.post("/user/group/getList", {}).then(response => {
            this.courseList = JSON.parse(JSON.stringify(response.data));
        });
    },
};
</script>

<style lang="scss" scoped>
.data {
    padding-top: 20px;
    padding-right: 30px;
}

.race_info {
    margin-bottom: 30px;

    >div {
        &:first-child {
            width: 500px;

            >div {
                &:first-child {
                    font-size: 26px;
                    margin-bottom: 20px;
                }

                &:last-child {
                    margin-top: 20px;
                    font-size: 16px;
                    color: #999;
                }
            }
        }

        &:last-child {
            >div {
                text-align: center;
                padding: 0 30px;

                +div {
                    border-left: 1px solid rgb(202, 202, 202);
                }

                >div {
                    &:first-child {
                        font-size: 16px;
                    }

                    &:last-child {
                        font-size: 16px;
                        color: #999;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.search_part {
    >* {
        +* {
            margin-left: 7px;
        }

        &:first-child(1),
        &:nth-child(2) {
            width: 120px;
        }

        &:nth-child(3) {
            width: 200px;
        }

        &:nth-child(6) {
            width: 220px;
        }
    }

    margin-bottom: 15px;
}

.page_part {
    text-align: center;
    margin-top: 20px;
}
</style>
